























import merge from "../utils/merge";
import DataContainer from "../common/DataContainer";
import humanizeString from "humanize-string";
import { titleCase } from "title-case";
import defaultComponent from "../utils/defaultComponent";

export default defaultComponent.apply({
  data() {
    return {
      modifiedProps: {}
    };
  },
  methods: {
    initialize() {
      if (!this.options.ext) {
        this.options.ext = {};
      }
      if (!this.options.ext.default) {
        this.options.ext.default = {};
      }
      this.options = this.decourateOptions(
        this.options,
        this.options.ext.default
      );
      this.modifiedProps = merge.clone({}, this._props);
      this.modifiedProps.xOptions = this.options;
    },
    decourateOptions(originOptions: any, originExtendOptions: any = {}) {
      const options: any = merge.clone(
        {
          content: {
            multiSelectActionButtons: {},
            topActionButtons: {},
            template: {
              itemActionButtons: {}
            },
            itemDetail: {
              topActionButtons: {},
              forms: {}
            }
          }
        },
        originOptions
      );

      let extendOptions: any = merge.clone(
        {
          topActionButtons: {},
          multiSelectActionButtons: {},
          itemActionButtons: {},
          itemDetailTopActionButtons: {},
          itemDetailForms: {}
        },
        originExtendOptions
      );

      for (const fieldName in options.content.displayFields) {
        const field = options.content.displayFields[fieldName];
        if (!field) {
          continue;
        }
        if (!field.text) {
          field.text = titleCase(humanizeString(fieldName));
        }
      }
      extendOptions = this.makeInsertForm(
        extendOptions,
        options.content.itemActivable
      );
      extendOptions = this.makeEditForm(
        extendOptions,
        options.content.itemActivable
      );
      extendOptions = this.makeItemActionDelete(
        extendOptions,
        options.content.itemActivable
      );

      extendOptions = this.extendTopActionButtons(extendOptions);
      extendOptions = this.extendMultiSelectActionButtons(extendOptions);
      extendOptions = this.extendItemActionButtons(extendOptions);

      options.content.topActionButtons = merge.clone(
        extendOptions.topActionButtons,
        options.content.topActionButtons
      );

      options.content.multiSelectActionButtons = merge.clone(
        extendOptions.multiSelectActionButtons,
        options.content.multiSelectActionButtons
      );
      const itemActionButtons = options.content.template.itemActionButtons;
      options.content.template.itemActionButtons = merge.clone(
        options.content.template.itemActionButtons,
        extendOptions.itemActionButtons
      );
      options.content.template.itemActionButtons = merge.clone(
        options.content.template.itemActionButtons,
        itemActionButtons
      );
      const itemDetailTopActionButtons = merge.clone(
        extendOptions.itemDetailTopActionButtons,
        options.content.itemDetail.topActionButtons
      );
      options.content.itemDetail.topActionButtons = merge.clone(
        options.content.itemDetail.topActionButtons,
        itemDetailTopActionButtons
      );
      options.content.itemDetail.forms = merge.clone(
        extendOptions.itemDetailForms,
        options.content.itemDetail.forms
      );

      return options;
    },
    makeInsertForm(extendOptions) {
      if (extendOptions.insertForm || extendOptions.insertTabBasedForm) {
        if (extendOptions.insertTabBasedForm) {
          extendOptions.topActionButtons.insert = merge.clone(
            {
              target: {
                dialog: {
                  content: {
                    content: {
                      type: "XTabBasedForm",
                      attrs: {
                        xOptions: this.makeTabBasedForm(
                          extendOptions.insertTabBasedForm,
                          extendOptions.defaultInputs,
                          {
                            content: {
                              horizontal: true
                            }
                          }
                        )
                      }
                    },
                    buttons: {
                      cancel: {},
                      save: {
                        ext: {
                          condition(self) {
                            const form = self.contentContainer.value;
                            const scrollableTabs = form.container.value;
                            if (!scrollableTabs) {
                              return false;
                            }
                            return scrollableTabs.isLastTab;
                          }
                        }
                      },
                      next: {
                        ext: {
                          hidden: false
                        }
                      }
                    }
                  }
                }
              }
            },
            extendOptions.topActionButtons.insert
          );
        } else {
          extendOptions.topActionButtons.insert = merge.clone(
            {
              target: {
                dialog: {
                  content: {
                    content: {
                      attrs: {
                        xOptions: this.makeForm(
                          extendOptions.insertForm,
                          extendOptions.defaultInputs
                        )
                      }
                    }
                  }
                }
              }
            },
            extendOptions.topActionButtons.insert
          );
        }
      }
      return extendOptions;
    },
    makeEditForm(extendOptions) {
      if (extendOptions.editForm || extendOptions.editTabBasedForm) {
        if (extendOptions.editTabBasedForm) {
          extendOptions.itemActionButtons.edit = merge.clone(
            {
              target: {
                dialog: {
                  content: {
                    content: {
                      type: "XTabBasedForm",
                      attrs: {
                        xOptions: this.makeTabBasedForm(
                          extendOptions.editTabBasedForm,
                          extendOptions.defaultInputs,
                          {
                            content: {
                              horizontal: true
                            }
                          }
                        )
                      }
                    },
                    buttons: {
                      cancel: {},
                      save: {
                        ext: {
                          condition(self) {
                            const form = self.contentContainer.value;
                            const scrollableTabs = form.container.value;
                            if (!scrollableTabs) {
                              return false;
                            }
                            return scrollableTabs.isLastTab;
                          }
                        }
                      },
                      next: {
                        ext: {
                          hidden: false
                        }
                      }
                    }
                  }
                }
              }
            },
            extendOptions.itemActionButtons.edit
          );
          if (
            extendOptions.editTabBasedForm &&
            extendOptions.editTabBasedForm.attrs &&
            extendOptions.editTabBasedForm.attrs.readonly
          ) {
            extendOptions.itemActionButtons.edit = merge.clone(
              extendOptions.itemActionButtons.edit,
              {
                target: {
                  dialog: {
                    content: {
                      content: {
                        makeAttrs(attrs: any = {}, dialog) {
                          const btn = dialog.context();
                          attrs.xData = new DataContainer(btn.context().item);
                          attrs.xModel =
                            dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xDisabled = new DataContainer(true);
                          return attrs;
                        }
                      },
                      buttons: {
                        save: {
                          ext: {
                            condition() {
                              return false;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            );
          }
        } else {
          extendOptions.itemActionButtons.edit = merge.clone(
            {
              target: {
                dialog: {
                  content: {
                    content: {
                      attrs: {
                        xOptions: this.makeForm(
                          extendOptions.editForm,
                          extendOptions.defaultInputs
                        )
                      }
                    }
                  }
                }
              }
            },
            extendOptions.itemActionButtons.edit
          );
          if (
            extendOptions.editForm &&
            extendOptions.editForm.attrs &&
            extendOptions.editForm.attrs.readonly
          ) {
            extendOptions.itemActionButtons.edit = merge.clone(
              extendOptions.itemActionButtons.edit,
              {
                target: {
                  dialog: {
                    content: {
                      content: {
                        makeAttrs(attrs: any = {}, dialog) {
                          const btn = dialog.context();
                          attrs.xData = new DataContainer(btn.context().item);
                          attrs.xModel =
                            dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xDisabled = new DataContainer(true);
                          return attrs;
                        }
                      },
                      buttons: {
                        save: {
                          ext: {
                            condition() {
                              return false;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            );
          }
        }
      }
      return extendOptions;
    },
    makeItemActionDelete(extendOptions) {
      if (extendOptions.delete) {
        extendOptions.itemActionButtons.delete = merge.clone(
          {
            ext: {
              confirmDisplayField: extendOptions.delete.confirmDisplayField
            }
          },
          extendOptions.itemActionButtons.delete
        );
      }
      return extendOptions;
    },
    extendTopActionButtons(extendOptions) {
      return this.extendButtons(extendOptions, "topActionButtons");
    },
    extendMultiSelectActionButtons(extendOptions) {
      return this.extendButtons(
        extendOptions,
        "multiSelectActionButtons",
        "multiSelectActionButton",
        (item, key) => {
          let action = item.action;
          if (!action) {
            action = {};
          }
          if (!action.key) {
            action.key = key;
          }
          if (!action.name) {
            action.name = humanizeString(action.key);
          }
          if (!action.targetName) {
            action.targetName = "confirm";
          }
          if (!item.target) {
            item.target = {};
          }
          item.target[action.targetName] = merge.clone(
            item.template.targets[action.targetName],
            item.target[action.targetName]
          );
          item.target[action.targetName].handlers = {
            initialize() {
              this.values.action = item.action;
            }
          };
          return item;
        }
      );
    },
    extendItemActionButtons(extendOptions) {
      return this.extendButtons(extendOptions, "itemActionButtons");
    },
    extendItemDetailTopActionButtons(extendOptions) {
      return this.extendButtons(extendOptions, "itemDetailTopActionButtons");
    },
    extendButtons(extendOptions, containerKey, templateKey, itemCallback) {
      if (extendOptions[containerKey]) {
        const items = {};
        for (const key in extendOptions[containerKey]) {
          let item = extendOptions[containerKey][key];
          if (!item) {
            continue;
          }
          let template = {};
          if (templateKey) {
            if (this.$vuetifyx.defaultDataTable.template[templateKey]) {
              template = merge.clone(
                template,
                this.$vuetifyx.defaultDataTable.template[templateKey]
              );
            }
          }
          if (
            this.$vuetifyx.defaultDataTable[containerKey] &&
            this.$vuetifyx.defaultDataTable[containerKey][key]
          ) {
            template = merge.clone(
              template,
              this.$vuetifyx.defaultDataTable[containerKey][key]
            );
          }
          item = merge.clone(template, item);
          if (itemCallback) {
            item = itemCallback(item, key);
          }
          items[key] = item;
        }
        extendOptions[containerKey] = items;
      }
      return extendOptions;
    },
    makeForm(formOptions, defaultInputs, template) {
      if (!formOptions.content.fields) {
        formOptions.content.fields = {};
      }
      if (formOptions.content.fieldNames) {
        for (const fieldName of formOptions.content.fieldNames) {
          formOptions.content.fields[fieldName] = merge.clone(
            defaultInputs[fieldName],
            formOptions.content.fields[fieldName]
          );
        }
      }
      if (formOptions.content.sections) {
        for (const sectionName in formOptions.content.sections) {
          const section = formOptions.content.sections[sectionName];
          for (const fieldName of section.fieldNames) {
            formOptions.content.fields[fieldName] = merge.clone(
              defaultInputs[fieldName],
              formOptions.content.fields[fieldName]
            );
          }
        }
      }

      if (!template) {
        return formOptions;
      }
      return merge.clone(template, formOptions);
    },
    makeTabBasedForm(tabBasedFormOptions, defaultInputs, template) {
      for (const formName in tabBasedFormOptions.ext.forms) {
        let formOptions = tabBasedFormOptions.ext.forms[formName];
        formOptions = this.makeForm(formOptions, defaultInputs);
        tabBasedFormOptions.ext.forms[formName] = formOptions;
      }
      if (!template) {
        return tabBasedFormOptions;
      }
      return merge.clone(template, tabBasedFormOptions);
    }
  },
  created() {
    this.initialize();
  }
});
